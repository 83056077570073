<template>
  <div class="ticket">
    <!-- 第一部分 -->

    <Head :ticket="ticket" :stopList="stopList" />
    <!-- 人员信息 -->
    <van-checkbox-group
      v-model="result"
      @change="seatList=['','']"
      :max="seatType[openSelectSeat].seatNum"
    >
      <van-cell-group ref="peocont">
        <van-cell v-for="(item,i) in personList" center :key="i">
          <template #title>
            {{item.username}}&nbsp;
            <van-tag plain type="primary">{{item.travelLevel}}</van-tag>&nbsp;
            <van-tag plain type="success" v-if="checkCanBuy(item,seatType[openSelectSeat].seatType)">合规</van-tag>
            <van-tag plain type="danger" v-if="!checkCanBuy(item,seatType[openSelectSeat].seatType)">超标</van-tag>
          </template>
          <template #label>
            <van-row @click="openEdit(item,i)">
              <span>
                <van-icon class="usericonfont" class-prefix="icon" name="idCard" />
                {{item.idCard}}&nbsp;-&nbsp;
              </span>
              <span>
                <van-icon class="usericonfont" class-prefix="icon" name="dianhua" />
                {{item.tel}}
                <span class="iconfont icon-xiugai edit-tel"></span>
              </span>
            </van-row>
          </template>
          <template #right-icon>
            <van-checkbox :name="item" ref="checkboxes" class="bigcheck" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-checkbox-group>
    <van-divider
      v-if="personList.length==0"
      :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >全部人员均有车票</van-divider>
    <!-- 人员信息-弹出层 -->
    <van-dialog v-model="show" title="修改人员信息" show-cancel-button :beforeClose="subimtTel">
      <van-form ref="editTel">
        <van-field
          v-model="tel"
          type="digit"
          label="手机号"
          name="validator"
          placeholder="请输入手机号"
          :rules="[{ validator, message: '请输入正确的手机号' }]"
        />
      </van-form>
    </van-dialog>
    <!-- 超标填表 -->
    <van-dialog v-model="compliant" title="超标说明" show-cancel-button :beforeClose="subimtCompliance">
      <van-form ref="editCompliant" class="explanCont">
        <van-field readonly v-model="explanation" label="超标缘由" @click="showPicker = true" />
        <van-field
          :rules="[{ required: true, message: '请填写超标缘由' }]"
          v-model="explanation1"
          rows="3"
          autosize
          label="备注"
          type="textarea"
          maxlength="100"
          placeholder="请输入备注"
          show-word-limit
        />
      </van-form>
      <div style="padding:0 1rem;font-size:12px;color:#777;display:flex">
        <div style="width:70px">
          <small>超标人员：</small>
        </div>
        <div style="padding-bottom:1rem">
          <small v-for="(item,i) in explanationText" :key="i">
            {{item.username}}（{{item.stand.trainSeatInfo_seatInfo_text||'差标解析异常'}}）
            <br />
          </small>
        </div>
      </div>
    </van-dialog>

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>

    <div class="gap"></div>

    <!-- 第二部分 -->
    <!-- 选座 -->
    <van-panel title="选票信息">
      <template #header>
        <div class="panel-head">
          <span class="van-cell__title">选票信息</span>
          <Logo v-if="ticket" :corp="ticket.corp" />
        </div>
      </template>
      <div style>
        <van-row
          class="chooseTicketBk"
          type="flex"
          justify="space-between"
          @click.self.stop="$refs.dropdown.toggle()"
        >
          <van-col>
            <van-dropdown-menu>
              <van-dropdown-item
                v-model="openSelectSeat"
                :options="seatType"
                ref="dropdown"
                @change="seatList=['','']"
              />
            </van-dropdown-menu>
          </van-col>
          <van-col
            class="useSeat"
            :style="'color:'+(parseInt(seatType[openSelectSeat].seatNum)>personList.length?'#969799':'red')"
          >
            余票:{{seatType[openSelectSeat].seatNum}}&nbsp;
            <span
              v-show="canSelect"
              :class="'useSeat'+(seatList[0].length+seatList[1].length==result.length?' blue':'')"
            >已选{{seatList[0].length+seatList[1].length}}/{{result.length}}个</span>
          </van-col>
        </van-row>
        <!-- </template> -->
        <van-row
          v-if="canSelect&&ticket.displaySeat[openSelectSeat].seatType!='无座'"
          type="flex"
          justify="space-between"
          class="site-col"
          v-for="item in [0,1]"
          :key="item"
        >
          <van-col>
            <span class="site">窗</span>
          </van-col>
          <van-col>
            <span
              :class="'site iconfont icon-zuoweiA '+(seatList[item].includes('A')?'select':'')"
              @click="selectSeat(item,'A')"
            ></span>
          </van-col>
          <van-col v-if="seatType[openSelectSeat].seatType=='二等座'">
            <span
              :class="'site iconfont icon-zuoweiB '+(seatList[item].includes('B')?'select':'')"
              @click="selectSeat(item,'B')"
            ></span>
          </van-col>
          <van-col>
            <span
              :class="'site iconfont icon-zuoweiC '+(seatList[item].includes('C')?'select':'')"
              @click="selectSeat(item,'C')"
            ></span>
          </van-col>
          <van-col>
            <span class="site">| |</span>
          </van-col>
          <van-col
            v-if="seatType[openSelectSeat].seatType=='二等座'||seatType[openSelectSeat].seatType=='一等座'"
          >
            <span
              :class="'site iconfont icon-zuoweiD '+(seatList[item].includes('D')?'select':'')"
              @click="selectSeat(item,'D')"
            ></span>
          </van-col>
          <van-col>
            <span
              :class="'site iconfont icon-zuoweiF '+(seatList[item].includes('F')?'select':'')"
              @click="selectSeat(item,'F')"
            ></span>
          </van-col>
          <van-col>
            <span class="site">窗</span>
          </van-col>
        </van-row>
      </div>
    </van-panel>
    <!-- 选座提示 -->
    <van-notice-bar v-if="canSelect" text="优先按指定坐席出票，默认无票时将转购其他坐席" left-icon="info-o" />
    <div class="gap"></div>

    <!-- 12306 -->
    <T12306 :corp="ticket.corp" v-if="config_show12306" ref="t12306" :peoList="result"/>
    <div class="gap" v-if="config_show12306"></div>
    
    <!-- 第三部分 -->
    <BodyList :totalPrice="totalPrice" />
    <div class="bottom-gap"></div>

    <!-- 第四部分 -->
    <van-submit-bar button-type="info" :price="totalPrice*100" button-text="提交占座" @submit="onSubmit"></van-submit-bar>

    <!-- 第五部分 - 提示 -->
    <TrainRules />

    <!-- 动画遮罩层 -->
    <LoadingAnimate v-if="wrapper" :text="'占座中'" />
    <!-- 圈圈遮罩层 -->
    <LoadingCircle :show="wrapper1" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getTrainStopStation, createTrainOrder } from "@/api/train";
import LoadingAnimate from "@/components/LoadingAnimate";
import LoadingCircle from "@/components/LoadingCircle";
import TrainRules from "@/components/TrainRules";
import Logo from "@/components/Logo";
import T12306 from "./components/12306";
import { refreshTicket } from "@/services/train";
import Head from "./components/InfoHead";
import BodyList from "@/components/InfoList";
import { checkSeat, CreateTrainPeoList } from "@/services/user";
import { REASONS, GoWhere, Show12306 } from "@/common/config.js";

export default {
  components: { LoadingAnimate, Head, Logo, BodyList, LoadingCircle, T12306, TrainRules },
  
  computed: {
    ...mapGetters(["ticket", "travelInfo", "personList"]),
    // 座位类型
    seatType: function () {
      let arr = [];
      let i = 0;
      let which = true;
      this.ticket.displaySeat.forEach((element, index) => {
        element.text = element.seatType + " (￥" + element.seatPrice + ")";
        if (which && parseInt(element.seatNum) != 0) {
          this.openSelectSeat = index;
          which = false;
        }
        element.value = i++;
        if (element.seatType != "停售") arr.push(element);
      });
      return arr;
    },
    // 总票价
    totalPrice: function () {
      return this.seatType[this.openSelectSeat].seatPrice * this.result.length;
    },
    // 可否选座
    canSelect: function () {
      return this.ticket.trainCode[0] == "C" || this.ticket.trainCode[0] == "G" || this.ticket.trainCode[0] == "D";
    },
    // 是否已选座位 
    chooseSeat: function(){
      return this.seatList[0].length + this.seatList[1].length < this.result.length
    }
  },

  activated() {
    window.setTitle(this.ticket.trainCode);
    // 默认选中所有人
    this.result = this.personList;
    this.wrapper = false
    this.wrapper1 = false
    // 获取经停站信息
    getTrainStopStation({
      TrainCode: this.ticket.trainCode,
      TrainDate: this.ticket.trainBeginDate,
      FromStation: this.ticket.fromStationName,
      ToStation: this.ticket.toStationName,
      Corp: this.ticket.corp,
    }).then((response) => {
      let arr = [];
      if (response && 0 in response)
        response.forEach((element) => {
          arr.push(...element.data);
        });
      this.stopList = arr;
    });
  },

  data() {
    return {
      config_show12306: Show12306, // 是否展示12306
      config_gowhere: GoWhere, // 下单成功跳转到哪儿
      showPicker: false,
      columns: REASONS,
      stopList: [], // 经停站列表
      show: false, // 编辑框卡关
      timetable: false, // 时刻表开关
      compliant: false, // 超标填表开关
      wrapper: false, // 遮罩层
      wrapper1: false, // 圈圈遮罩层
      result: [], // 已选人员
      openSelectSeat: 0,
      tel: null, // 编辑框初始值
      explanation: REASONS[0], // 超标类型
      explanation1: "", // 超标备注
      explanationText: [], // 超标说明
      index: null, // 编辑框位置
      seatList: ["", ""], // 已选座位列表[ABCDF-ABCDF] 一排-二排
    };
  },

  methods: {
    // 选超标理由
    onConfirm(value) {
      this.explanation = value;
      this.showPicker = false;
    },

    // 合规检查
    checkCanBuy(userRole = {}, seatType = "二等座") {
      return checkSeat(userRole, seatType);
    },

    // 打开修改手机号
    openEdit(data, index) {
      // 保存手机号和下标，保存用
      this.tel = data.tel;
      this.index = index;
      this.show = true;
    },

    // 手机号校验
    validator(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },

    // 单击选座
    selectSeat(index, value) {
      let obj = this.seatList[index];
      if (obj.includes(value)) {
        // 受限于vue，数组内部的绑定需要借用splice来实现
        this.seatList.splice(index, 1, obj.replace(value, ""));
      } else {
        if (this.seatList[0].length + this.seatList[1].length < this.result.length) {
          this.seatList.splice(index, 1, obj + value);
        }
      }
    },

    // 点击提交按钮，该方法仅做一些简单的验证，之后还需要调用合规检查，最后才可以提交占座
    onSubmit: async function () {
      // 如果开启并登录12306则检查出行人是否都加入常旅客
      if(this.$refs.t12306&&!!this.$refs.t12306.loginData&&this.$refs.t12306.noList.length>0) return this.$toast.fail("部分人员尚未添加至常旅客");
      if (this.result.length <= 0) return this.$toast.fail("尚未选择人员");
      this.wrapper1 = true; // 打开遮罩层，ajax期间啥也别点
      let checkPrice = await this.$store.dispatch(
        "project/updateAppPrice",
        this.totalPrice
      );
      this.wrapper1 = false; // 关闭遮罩层
      if (this.travelInfo.isControl == "yes" && !checkPrice)
        return this.$toast.fail("行程限额不足");
      if (this.canSelect &&this.chooseSeat) {
        this.$dialog
          .confirm({
            title: "提示",
            message: "还有人未选座，是否继续提交订单？",
          })
          .then(() => {
            this.checkCompliance();
          });
      } else {
        this.checkCompliance();
      }
    },

    // 合规检查
    checkCompliance() {
      let errorlen = 0;
      let explanationArr = [];
      if (this.seatType[this.openSelectSeat].seatNum <= 0)
        return this.$toast.fail("余座不足");
      this.result.forEach((peo) => {
        let check = this.checkCanBuy(
          peo,
          this.seatType[this.openSelectSeat].text
        );
        if (!check) {
          errorlen++;
          explanationArr.push(peo);
        }
      });
      // 如果大于0说明有超标
      if (errorlen > 0) {
        this.explanationText = explanationArr;
        this.compliant = true;
      } else {
        this.grabSeat();
      }
    },

    // 提交申请单，通过的话调用抢座
    subimtCompliance(action, done) {
      if (action === "confirm") {
        this.$refs.editCompliant
          .validate()
          .then(() => {
            this.grabSeat();
            done();
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },

    // 抢座、占座
    async grabSeat() {
      let seatInfo = this.seatType[this.openSelectSeat];
      this.wrapper = true;
      this.ticket.hasChooseSeat = this.chooseSeat?'false':'true' // 是否选座
      let seates = '' // 已选座位
      if(this.seatList[1]!=""&&this.seatList[0]!="") seates = this.seatList.join('-')
      else if(this.seatList[1]!="") seates = this.seatList[1]
      else if(this.seatList[0]!="") seates = this.seatList[0]
      this.ticket.chooseSeats = seates

      // 加12306等信息 2021-2-23
      let userInfo12306 = JSON.parse(window.localStorage.getItem("t12306")||'{}')
      this.ticket.ticketModel=(this.$refs.t12306&&!!this.$refs.t12306.loginData&&userInfo12306.accountNo&&userInfo12306.accountPwd)?'1':'0', // 12306自购1、代购0
      this.ticket.accountNo=userInfo12306.accountNo||'',
      this.ticket.accountPwd=userInfo12306.accountPwd||'',
      this.ticket.acceptNoSeat='0' // 是否接受无座、 0不接受、1接受

      createTrainOrder({
        trainInfo: this.ticket,
        ticketInfo: seatInfo,
        travelUser: CreateTrainPeoList(
          this.result,
          seatInfo.text,
          this.explanation,
          this.explanation1
        ),
        contactPerson: this.travelInfo.loginUser,
        applyNo: this.travelInfo.appId,
      })
        .then(async (response) => {
          let ticket1;
          while (1) {
            if (this.$route.name != "orderTrain") return;
            ticket1 = await refreshTicket(response.data);
            if (ticket1) break;
          }
          this.$store.dispatch("history/setOrderItem", ticket1.order);
          switch (this.config_gowhere) {
            case 'detail': // 转到详情
              this.$router.push("/Payment")
              break;
            default: // 默认转到订单列表
              this.$router.push({ path: "/plan" }); 
              break;
          }
          this.wrapper = false;
        })
        .catch((err) => {
          this.wrapper = false;
        });
    },

    // 修改手机号 确认
    subimtTel(action, done) {
      if (action === "confirm") {
        this.$refs.editTel
          .validate()
          .then(() => {
            // 把显示和结果集的手机号都修改一下
            this.personList[this.index].tel = this.tel;
            done();
          })
          .catch((err) => {
            done(false);
          });
      } else {
        done();
      }
    },
  },
};
</script>

<style lang="less" src="@/styles/orderCommon.less" scoped></style>
<style lang="less" src="@/styles/orderHeadCommon.less" scoped></style>
<style lang="less">
.explanCont textarea {
  height: 66px;
  max-height: 120px;
}
.ticket .van-dropdown-menu__title {
  font-size: 14px;
}
</style>
<style lang="less" scoped>
.chooseTicketBk {
  padding: 0 1rem;
}
</style>
