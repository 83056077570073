<template>
    <!-- 12306 -->
    <van-panel title>
        <template #header>
            <div class="panel-head">
                <div>
                    <span class="iconfont icon-huochezhanxiao t12306"></span>绑定12306
                </div>
                <van-button type="primary" size="mini" @click="show=true" v-if="!loginData">登录</van-button>
                <van-button type="default" size="mini" @click="logout" v-else>退出</van-button>
            </div>
        </template>
        <van-cell v-if="loginData" :title="loginData.accountName" is-link value="常旅客" @click="goTravelList"/>

        <!-- 登录弹窗 -->
        <van-dialog v-model="show" title="登录12306" show-cancel-button :beforeClose="subimt">
          <van-form ref="lgoin12306">
            <van-field
              v-model="username"
              name="账号"
              label="账号"
              placeholder="请输入12306账号"
              :rules="[{ required: true, message: '请填写账号' }]"
            />
            <van-field
              v-model="password"
              type="password"
              name="密码"
              label="密码"
              placeholder="请输入12306密码"
              :rules="[{ required: true, message: '请填写密码' }]"
            />
            <van-field name="switch" label="验证码开关">
              <template #input>
                <van-switch v-model="checked" size="20" />
              </template>
            </van-field>
            <van-field
              v-if="checked"
              v-model="sms"
              center
              clearable
              label="验证码"
              placeholder="请输入短信验证码"
              :rules="[{ required: true, message: '请填写密码' }]"
            >
            </van-field>
            <span class="list-text-big red tip">{{tip}}</span>
          </van-form>
        </van-dialog>
        <van-notice-bar v-if="noList.length>0" :text="noList.map(e=>e.username).join('、')+'未绑定12306常旅客，请点击上方“常旅客”绑定。'" left-icon="info-o" />

    </van-panel>
</template>

<script>
import {loginRailwayAccount, queryTraveler} from '@/api/train'
export default {
  name: "t12306",
  props: {
    corp: {
      type: String
    },
    // 已选人员
    peoList: {
      type: Array
    }
  },
  data() {
    return {
      checked: false, // 手动开关
      travelLlist: [], // 常旅客名单
      loginData: null, // 12306信息
      show: false, // 登录弹窗开关
      username:'', // 用户名
      password:'', // 密码
      sms:'', // 验证码
      tip:'', // 提示文本
    };
  },
  computed: {
    // 不在常用旅客的列表
    noList: function () {
      if(!this.loginData) return [] // 没登陆就认为都合规
      let arr = this.peoList.filter(e=>{
        let temp = this.travelLlist.filter(travel=>{
          return travel.passengerName==e.username&&travel.certNo.slice(-3)==e.idCard.slice(-3)
        })
        return temp.length<=0
      })
      return arr;
    },
  },
  activated() {
    this.tip=""
    this.show = false
    this.username = ''
    this.password = ''
    this.query()
  },
  async mounted(){
    let user = JSON.parse(window.localStorage.getItem("t12306"))
    if(user&&user.accountNo&&user.accountPwd){
      this.username = user.accountNo
      this.password = user.accountPwd
      if(await this.login()) this.query()
    }
  },
  methods: {
    // 登录提交按钮
    subimt(action, done) {
      if (action === "confirm") {
        this.tip=""
        this.$refs.lgoin12306
          .validate()
          .then(async() => {
            let f = await this.login()
            if(f) {
              this.tip=""
              this.query()
            }
            done(f)
          })
          .catch((err) => {
            done(false);
          });
      } else {
        done();
      }
    },

    // 登录方法
    login(){
      return new Promise((resolve, reject) => {
        loginRailwayAccount({
          accountNo: this.username,
          accountPwd: this.password,
          loginModel: "0",
          validCode: this.sms,
          corp: this.corp
        })
        .then(res => {
          if(!res||!res.success){
            this.$toast.fail('系统异常');
            resolve(true)
          }
          this.loginData=res.data
          if(!!this.username&&!!this.password)
            window.localStorage.setItem("t12306", JSON.stringify({
              accountNo: this.username,
              accountPwd: this.password,
            }))
          resolve(true)
        })
        .catch(err=>{
          // 如果有错，提示+验证码框（验证码需要用户发666到12306获取）
          err = err.toString()
          this.tip = err
          if(err.includes('短信')) this.checked = true
          resolve(false)
        })
      })
    },

    // 退出
    logout(){
      this.$dialog.confirm({
        title: '提示',
        message: '确定要退出12306账号吗？',
      })
        .then(() => {
          this.loginData=null
          window.localStorage.removeItem("t12306")
        })
    },

    // 查询常旅客
    query(cb=null) {
      let req = JSON.parse(window.localStorage.getItem("t12306"))
      if(!req||!req.accountNo||!req.accountPwd) return // 信息不全不查常旅客
      req.queryType = "0"
      req.corp = this.corp
      queryTraveler(req).then(res=>{
        if(res&&res.success){
          this.travelLlist = res.data.memberLinkers
        }
        // 获取失败再次尝试，如果还失败，退出登录
        else{
          if(cb) cb()
          else this.query(()=>{ this.loginData=null })
        }
      })
      .catch(()=>{
        if(cb) cb()
        else this.query(()=>{ this.loginData=null })
      })
    },

    // 前往常旅客页面
    goTravelList(){
      this.$store.dispatch('train/setTravelList',{
        corp: this.corp,
        noList: this.noList,
        travelLlist: this.travelLlist
      })
      this.$router.push({ name: "passengerList"});
    }
  },
};
</script>

<style lang="less" scoped>
@import '../../../styles/theme.less';
.t12306 {
    font-size: @font-size-lg;
    line-height: 22px;
    color: @blue;
    padding-right: 0.4rem;
}
.font-cent{
    display: flex;
    align-items: center;
    padding-left: 30vw;
    color: @gray-6;
}
.tip{
  display: inline-block;
  padding: 0.4rem 1rem 1rem;
}
</style>