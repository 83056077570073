import { render, staticRenderFns } from "./OrderTrain.vue?vue&type=template&id=a4068886&scoped=true&"
import script from "./OrderTrain.vue?vue&type=script&lang=js&"
export * from "./OrderTrain.vue?vue&type=script&lang=js&"
import style0 from "@/styles/orderCommon.less?vue&type=style&index=0&id=a4068886&lang=less&scoped=true&"
import style1 from "@/styles/orderHeadCommon.less?vue&type=style&index=1&id=a4068886&lang=less&scoped=true&"
import style2 from "./OrderTrain.vue?vue&type=style&index=2&lang=less&"
import style3 from "./OrderTrain.vue?vue&type=style&index=3&id=a4068886&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4068886",
  null
  
)

export default component.exports